var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = symbolObservablePonyfill;

function symbolObservablePonyfill(root) {
  var result;
  var _Symbol = root.Symbol;

  if (typeof _Symbol === "function") {
    if (_Symbol.observable) {
      result = _Symbol.observable;
    } else {
      result = _Symbol("observable");
      _Symbol.observable = result;
    }
  } else {
    result = "@@observable";
  }

  return result;
}

;
export default exports;